/* === DESKTOP FIRST === */

/* xl <= Extra extra large (xxl) */
@media (max-width : 1400px) {

}

/* lg <= Extra large (xl) */
@media (max-width : 1200px) {

	.top-line__menu::after {
		left: 55%;
	}
	.content-decoration {
		position: absolute;
		left: 0;
		top: 585px;
		width: 13.3vw;
		width: 23.3vw;
		height: auto;
	}

}

/* md <= Large (lg) */
@media (max-width : 992px) {


	.top-line--light .top-line__menu-wrapper nav .top-line__menu ul li, .top-line--light .top-line__menu-wrapper nav .top-line__menu ul li a{
		color: var(--text);
	}
	.top-line--light .top-line__menu-wrapper nav .top-line__menu .top-menu--m{
		background-color: rgba(255, 255, 255, 0.5);
		box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.1);
	}
	.top-menu {
		display: none;
		flex-direction: column;
		text-align: center;
		position: absolute;
		z-index: 1;
		background-color: var(--medium-bg);
		width: 100%;
		left: 0;
		right: 0;
	}
	.top-menu__item--active::before {
		display: none;
	}
	.top-menu li{
		margin-bottom: 5px;
		padding: 5px;
		border-bottom: 1px solid var(--bg-light);
		box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.1);
	}
	.top-menu--m {
		display: block;
		text-align: center;
	}
.top-menu__item--active::before, .top-menu li:hover ::before {
	display: none;
}
	.last-news__heading-wrapper img{
		max-height: 90px;
	}
	.usefull-stuff::after {
		display: none;
	}
	.bloh__decoration-imgs img{
		max-width: 157px;
	}

	.about-us__pagination {
		flex-direction: column;
	}


	.content__wheel img{
		height: 250px;
		width: 250px;
		flex: 0 30%;
	}
	.wheel__options {
		height: 250px;
		width: 250px;
	}
	.content__desc {
		flex: 0 70%;
	}


	.logbook__content {
		flex-direction: column;
		padding-left: 20px;
	}
	.logbook__stat {
		align-self: center;
	}
	.logbook__days-event {
		border-bottom: 1px solid var(--text-faint);
		margin-bottom: 20px;
		max-width: 800px;
		padding: var(--sm-margin);
	}

}

/* sm <= Medium (md) */
@media (max-width : 768px) {

	.last-news__heading-wrapper img{
		display: none;
	}
	.usefull-item__content {
		border-bottom: 1px solid #000;
		margin: var(--sm-margin) 0;
	}
	.main-footer {
		font-size: 16px;
	}

	.content-decoration {
		top: 30.4vw;
		left: -85px;
		width: 18.3vw;
		height: auto;
	}
	.afonarizm-page__content {
		margin-left: 30px;
	}
	.big-circ {
		height: 30.4vw;
		width: 30.4vw;
	}

	.small-circ	{
		height: 10.4vw;
		width: 10.4vw;
	}

	.top-part {
		width: 80%;
		height: 30.4vw;
		left: 15.8vw;
	}
	.main-line {
		width: 80%;
		margin-left: 15.8vw;
		margin-top: 0;
	}

	.mobile-footer div:nth-child(1), .mobile-footer div:nth-child(3) {
		text-align: left;
	}
	.mobile-footer div:nth-child(2), .mobile-footer div:nth-child(4) {
		text-align: right;
	}
	.main-footer__to-top {
		top: var(--sm-margin);
		right: var(--sm-margin);
		bottom: unset;
	}

	.bloh__decoration-imgs img{
		max-width: 107px;
	}
	.categories__items {
		justify-content: flex-end;
	}

	.article__heading {
		margin-top: 33px;
	}



	.horoscope__content {
		flex-direction: column;
		flex-direction: column-reverse;
	}
	.content__desc, .content__wheel {
		flex: 0 100%;
	}

}

/* xs <= Small (sm) */
@media (max-width : 576px) {

	.top-line__menu-wrapper {
		flex-direction: column;
	}
	.top-line__menu::after {
		left: 0;
	}
	.menu-ez {
		margin-top: 0;
	}
	.news-list-item__img{
		width: 100%;
		min-height: 182px;
		height: auto;
		position: relative;
	}

	.afonarizm-page__heading {
		font-size: 28px;
	}
	.pagination-item {
		font-size: 20px;
		padding: 0 10px;
	}
	.bloh__decoration-imgs img{
		max-width: 80px;
	}

	.article__heading h3{
		font-size: 28px;
	}
	.article__heading p{
		margin-bottom: 10px;
	}
	.article__content p{
		font-size: 18px;
	}
	.article__info {
		flex-direction: column;
	}
	.article__info p:nth-child(2){
		align-self: flex-end;
	}

	.about-us article {
		font-size: 18px;
	}



	.modal-humor-pump h2{
		font-size: 22px;
	}
	.humor-pump__decoration::before {
/*		right: 30px;
		left: 30px;
*/	}


	.logbook__content::after {
		display: none;
	}
	.logbook__content {
		padding-left: 0;
	}
	.logbook__content p, .logbook__content li{
		font-size: 18px;
	}


	.modal__container {
		height: 86vh;
	}
	.poem {
	}
	.poem__content {
		font-size: 16px;
	}
	.poem small{
		font-size: 16px;
	}
    .poem__heading {
        margin-top: 25px;
    }


	.t-society__content img{
		width: 100%;
	}

}


/* === MOBILE FIRST === */

/* Custom (xs) */
@media (min-width : 0) {

}

/* Small (sm) */
@media (min-width : 576px) {

}

/* Medium (md) */
@media (min-width : 768px) {
    .categories__items li:hover {
        transform: translateY(-10px);
        box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
}

}

/* Large (lg) */
@media (min-width : 992px) {

}

/* Extra large (xl) */
@media (min-width : 1200px) {
	.text {
		font-size: 18px;
	}
}

/* Extra extra large (xxl) */
@media (min-width : 1400px) {
	.text {
		font-size: 22px;
	}
}
