/* USER VARIABLES SECTION */

:root {
	--accent: #ff7fed;
	--second-accent: #2585ae;
	--text: #333;
	--text-faint: rgba(51, 51, 51, 0.6);
	--info-text: rgba(51, 51, 51, 0.75);
	--regular-text: 20px;
	--lineheight: 1.65;
	--userfont: underdog-ez, sans-serif;
	--systemfont: -apple-system, BlinkMacSystemFont, Arial, sans-serif;

	--bg-light: #fefefe;
	--yellow-bg-light: #ffffe8;
	--medium-bg: #f7f7f7;
	--needle-color: #a0a0a0;
	--pump-green: #267f00;
	--gutter: 30px;
	--st-margin: 65px;
	--sm-margin: 19px;
}

/* BOOTSTRAP SETTINGS SECTION */

/* gutter 20px (10px + 10px). Comment this code for default gutter start at 1.5rem (24px) wide. */
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl { --bs-gutter-x: .625rem; }
.row, .row > * { --bs-gutter-x: 1.25rem; }

/* FONTS LOAD SECTION */

@font-face { src: url("../fonts/roboto-regular-webfont.woff2") format("woff2"); font-family: "roboto-ez"; font-weight: 400; font-style: normal; }
@font-face { src: url("../fonts/roboto-italic-webfont.woff2") format("woff2"); font-family: "roboto-ez"; font-weight: 400; font-style: italic; }
@font-face { src: url("../fonts/roboto-bold-webfont.woff2") format("woff2"); font-family: "roboto-ez"; font-weight: 700; font-style: normal; }
@font-face { src: url("../fonts/roboto-bolditalic-webfont.woff2") format("woff2"); font-family: "roboto-ez"; font-weight: 700; font-style: italic; }
@font-face { src: url("../fonts/woff2/Underdog-Regular.woff2") format("woff2"); font-family: "underdog-ez"; font-weight: 700; font-style: italic; }
@font-face { src: url("../fonts/woff2/RubikBeastly-Regular.woff2") format("woff2"); font-family: "rubik-beasty-ez"; font-weight: 700; font-style: italic; }

/* GENERAL CSS SETTINGS */

::placeholder { color: #666; }
::selection { background-color: var(--accent); color: #fff; }
input, textarea { outline: none; }
input:focus:required:invalid, textarea:focus:required:invalid { border-color: red; }
input:required:valid, textarea:required:valid { border-color: green; }

body {
	font-family: var(--userfont);
	font-size: var(--regular-text);
	line-height: var(--lineheight);
	color: var(--text);
	min-width: 320px;
	position: relative;
	overflow-x: hidden;
}

a {
	color: var(--text);
	transition: color .25s ease-in-out;
	text-decoration: none;
	position: relative;
	transition: color .25s ease-in-out;
	border-bottom: 1px solid var(--text-faint);
}
a:hover {
	color: var(--accent);
}

.ez-link, .ez-link--underline {
	color: var(--text);
	transition: color .25s ease-in-out;
	text-decoration: none;
	position: relative;
	border: none;
}
.ez-link--underline::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 2px;
	left: 0;
	right: 0;
	border-bottom: 1px solid var(--text);
	width: 0;
	z-index: 1;
	transition: width .35s ease-in-out, border .5s ease-in-out;
}
.ez-link:hover, .ez-link--underline:hover {
	color: var(--accent);
	text-decoration: none;
}
.ez-link--underline:hover::before {
	content: '';
	width: 100%;
	border-color: var(--accent);
}

/* COMMON */

.historical-note::before, .usefull-stuff, .main-footer::before, .bloh__categories::before, .about-us, .logbook .modal__container::before{
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-image: url(../images/notebook-bg.png);
	background-repeat: repeat;
	background-color: #fff;
}

/* USER STYLES */

.top-line, .top-line--light{
	margin-top: 35px;
	text-align: right;
}
.top-line__menu-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;	
	position: relative;
}

.top-line__menu::after {
	content: '';
	position: absolute;
	/*top: 0;*/
	height: 60px;
	bottom: -50px;
	right: 98px;
	left: 67%;
	background-image: url(../images/footprints.png);
	background-size: cover;
	background-repeat: no-repeat;
}

.logo, .logo--light {
	font-family: rubik-beasty-ez;
	font-size: 50px;
	user-select: none;
}
.logo a{
    border-bottom: none;
    transition: color 1s ease-in-out;
}

.menu-ez {
	width: 98px;
	height: auto;
	margin-top: -20px;
	offset-rotate: 90deg;
}
.top-menu--m {
	padding: 9px var(--sm-margin);
	display: none;
}
.top-menu--m img{
	width: 30px;
	height: auto;
}
.top-menu {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	position: relative;
	transition: visibility .25s ease-in-out;
}
.top-menu li {
	list-style-type: none;
	display: inline-block;
	margin: 0 var(--sm-margin);
	position: relative;
}
.top-menu li a::before {
	content: '';
	width: 0;
	transition: width .35s ease-in-out;
}
.top-menu li a{
	text-decoration: none;
	display: block;
}
.top-menu__item--active::before, .top-menu li:hover ::before {
	content: '';
	position: absolute;
	background-image: url(../images/underline.png);
	background-repeat: no-repeat;
	background-size: contain;
	height: 40px;
	width: 100%;
	margin-top: 25px;
}


.disclamier {
	background-color: yellow;
	position: relative;
	width: 100%;
	right: 0;
	left: 0;
}
.disclaimer__text {
	padding: var(--sm-margin);
}
.disclaimer__close-btn {
	position: absolute;
	top: 9px;
	right: 9px;
	border: 1px solid var(--text);
	width: 23px;
	height: 23px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;	
	transition: background-color .25s ease-in-out;
}
.disclaimer__close-btn:hover {
	background-color: #fff;
}


.historical-note {
	margin: var(--st-margin) 0;
	padding: 38px;
	padding-left: 56px;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
	position: relative;
}
.historical-note::before {
	content: '';
	background-size: 4%;
	z-index: -1;
}
.historical-note::after, .usefull-stuff::after, .about-us::after, .logbook__content::after {
	content: '';
	position: absolute;
	top: 30px;
	bottom: 30px;
	right: 0;
	left: var(--sm-margin);
	background-image: url(../images/decoration-holes.png);
	background-repeat: repeat-y;
	z-index: -1;
}
.historical-note__heading {
	font-size: 28;
	margin-bottom: var(--sm-margin);
	border-bottom: 1px solid #000;
	display: inline-block;
}
.historical-note__text a{
	color: #000;
	font-size: 21px;
	white-space: nowrap;
}

/*NEWS*/

.last-news, .last-news--bloh {
	position: relative;
}
.last-news__heading-wrapper {
	display: flex;
	justify-content: center;
	justify-content: space-between;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 23px;
}
.last-news__heading-wrapper img{
	max-height: 108px;
}
.last-news__heading {
	text-align: center;
}
.last-news__heading h3{
	font-size: 28px;
}
.last-news__heading p{
	font-size: 18px;
	padding: 0 var(--sm-margin);
}

.news-list-item {
	background-color: var(--medium-bg);
	transition: background-color .25s ease-in-out;
	margin-bottom: var(--gutter);
	position: relative;
}
.news-list-item__wrapper{
	width: 100%;
	padding: var(--sm-margin);
	height: 100%;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.news-list-item__wrapper:hover {
	background-color: var(--yellow-bg-light);
}
.news-list-item__wrapper:hover  .news-list-item__img{
	filter: grayscale(10%);
}
.news-list-item h3 {
	font-size: 20px;
	text-align: center;
	margin-bottom: 10px;
}
.news-list-item__img {
	width: 312px;
	height: 182px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin: 0 auto 10px;
	filter: grayscale(100%);
	transition: filter .25s ease-in-out;
	flex: none;
}
.news-list-item p {
	font-size: 16px;
	margin: 0;
}	
.news-list-item__wrapper>p::after {
    content: '...'
}

.news-list-item__footer {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex: 0 0 16px;
}
.news-list-item__footer p{
	color: var(--text-faint);
}
.news-list-item__footer a{
	font-size: 17px;
}

.show-more {
	text-align: center;
	margin-bottom: 54px;
}


/*USEFULL STUFF*/

.usefull-stuff {
	background-size: 3%;
	position: relative;
	margin-bottom: var(--st-margin);
	z-index: 0;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.usefull-stuff__heading {
	font-size: 28px;
	text-align: center;
	margin: 0;
	padding: 38px 0 var(--sm-margin);
	text-decoration: underline;
}

.usefull-item__heading {
	text-align: center;
	font-size: 20px;
}
.usefull-item__content {
	font-size: 16px;
	position: relative;
}
.usefull-item__content p {
	margin: 0;
}
.usefull-item__button {
	text-align: center;
	padding-bottom: var(--sm-margin);
    cursor: pointer;
}
.usefull-item__button h4{
	text-decoration: underline;
	font-size: 20px;
	cursor: pointer;
}

.usefull-item__stat li{
	padding: 0;
	margin: 0;
}
.usefull-item__stat li span{
	font-size: 17px;
	color: #000;
}
.usefull-item__stat li img {
	max-width: 55px;
	max-height: 66px;
	margin: auto 0;
	margin-left: var(--sm-margin);
	height: auto;
}

.usefull-stuff__show-more {
	text-align: right;
	padding: var(--sm-margin);
}


/*MAIN FOOTER*/
.main-footer {
	position: relative;
	padding-top: 54px;
	margin-bottom: 0;
	/*font-size: 16px;*/
	box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}
.main-footer::before {
	content: '';
	background-size: 3%;
	z-index: -1;
}
.main-footer::after {
	content: '';
	position: absolute;
	top: var(--sm-margin);
	bottom: 0;
	right: var(--sm-margin);
	left: var(--sm-margin);
	background-image: url(../images/decoration-holes-horizontal.png);
	background-repeat: repeat-x;
	z-index: -1;
}

.main-footer__item {
	text-align: center;
	margin-bottom: var(--gutter);
	position: relative;
}
.main-footer__item p{
	padding: 0 var(--sm-margin);
}
.main-footer__item p a{
	white-space: nowrap;
	color: #000;
	text-decoration: wavy underline;	
}
.main-footer__item img{
	/*width: 100%;*/
	height: auto;
	max-height: 140px;
	max-width: 100%;
}

.main-footer__copyright {
	text-align: center;
	margin: 0;
	padding: 15px 0;
	font-size: 16px;
}

.mobile-footer {
	text-align: center;
}
.mobile-footer a{
	border-bottom: 1px solid var(--text);
	white-space: nowrap;
}
.mobile-footer>div {
	margin: var(--sm-margin) 0;
}
.mobile-footer div:nth-child(1) {
	text-align: left;
}
.mobile-footer div:nth-child(3) {
	text-align: right;
}
.main-footer__to-top {
	position: absolute;
	right: var(--st-margin);
	bottom: var(--st-margin);
	background-color: #fff;
	padding: calc(var(--sm-margin)/2) var(--sm-margin);
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
}


/*AFONARIZMS PAGE*/

.afonarizm-page {
	background-color: var(--second-accent);
    padding-top: 1px;
    padding-bottom: 1px;
    z-index: 0;
    position: relative;
}


.logo--light, .top-line--light .top-line__menu-wrapper nav .top-line__menu ul li, .top-line--light .top-line__menu-wrapper nav .top-line__menu ul li a{
	color: #fff;
}
.top-line--light .top-line__menu-wrapper nav .top-line__menu ul li a:hover{
	color: var(--accent);
}

.afonarizm-page__heading {
	font-size: 38px;
	color: #fff;
	text-align: center;
	margin-top: var(--st-margin);
}
.afonarizm-page__desc {
	text-align: center;
	color: #fff;
	margin-bottom: var(--st-margin);
	padding: 0 9px;
}
.afonarizm-page-pagination {
	margin-bottom: 75px;
}

.pagination {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.pagination a {
	border: none;
}
.pagination-item {
	padding: 0 15px;
	margin: 0 7.5px;
	border-radius: 5px;
	font-size: 28px;
	transition: margin .25s ease-in-out, box-shadow .25s ease-in-out, color .25s ease-in-out;
    z-index: 1;
}
.pagination--light .pagination-item {
	background-color: #fff;
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
}
.pagination--dark .pagination-item {
	background-color: var(--needle-color);
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
}
.pagination-item:hover, .pagination-item--active {
	margin-top: -16px;
	box-shadow: -4px 4px 4px rgba(255, 255, 255, 0.25);
}
 .pagination-item:hover{
    color: var(--accent);
    cursor: pointer;
}
.pagination .pagination-item--inactive, .paginantion .pagination-item--inactive:hover {
	opacity: 0.5;
	box-shadow: none;
	margin-top: 0;
    color: var(--text);
}

.afonarizm-page__content {
	margin-bottom: var(--st-margin);
	text-align: center;
	position: relative;
}
.content-decoration {
	vertical-align: top;
	margin-left: 60px;
	width: 255px;
	height: 719px;
	image-rendering: pixelated;
	image-rendering: crisp-edges;
}
.content-wrapper {
	position: relative;
	display: inline-block;
}

.big-circ {
	height: 585px;
	/*height: 30.4vw;*/
	width: 585px;
	/*width: 30.4vw;*/
	background-color: #eee;
	box-shadow: -10px 10px 4px rgba(0, 0, 0, 0.25);
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.small-circ	{
	height: 201px;
	/*height: 10.4vw;*/
	width: 201px;
	/*width: 10.4vw;*/
	background-color: #626262;
	box-shadow: inset 20px 20px 4px rgba(0, 0, 0, 0.25);
	border-radius: 100%;
}

.top-part {
	width: 476px;
	/*width: 24.8vw;*/
	height: 585px;
	/*height: 30.4vw;*/
	background-color: var(--medium-bg);
	border-radius: 0px 280px 0px 0px;
	position: absolute;
	left: 304px;
	/*left: 15.8vw;*/
	top: 0;
	z-index: -1;
}
.main-line {
	width: 514px;
	min-height: 800px;
	background-color: var(--medium-bg);
	margin-left: 266px;
	margin-top: -50px;
	padding-top: var(--st-margin);
	z-index: -1;
	position: relative;
}

.main-line__item {
	padding: var(--st-margin) var(--sm-margin);
	position: relative;

}
.main-line__item::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: var(--sm-margin);
	left: var(--sm-margin);
	background-image: url(../images/decoration-holes-horizontal.png);
	background-repeat: repeat-x;
	z-index: -1;
}

/*BLOH*/

.bloh__heading-wrapper {
	margin: 62px 0 35px;
	width: 100%;
	text-align: center;
}
.bloh__decoration-imgs {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.bloh__decoration-imgs img{
	width: 100%;
	height: auto;
	max-width: 207px;
	image-rendering: pixelated;
	image-rendering: crisp-edges;
}
.bloh__heading-wrapper h3{
	font-size: 38px;
	text-align: center;
	margin-bottom: 50px;
}

.bloh__categories {
	position: relative;
	padding: 26px 19px;
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
	margin-bottom: 33px;
}
.bloh__categories::before {
	z-index: -1;
	background-size: 3%;
}

.categories__heading {
	display: inline-block;
}
.categories__items {
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-size: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.categories__items li, .article__heading p {
	margin: 0;
	padding: 0;
	font-size: 16px;
	padding: 5px 15px;
	background-color: lightblue;
	margin-bottom: 15px;
	margin-right: 15px;
	border-radius: 19px;
	cursor: pointer;
	transition: transform .25s ease-in-out,  box-shadow .25s ease-in-out;
}
.categories__items .active-category{
	transform: translateY(-10px);
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
}

.bloh__content {
	margin-bottom: 35px;
}

.bloh__pagination--bottom {
	margin-bottom: 55px;
}

.bloh__alert {
    text-align: center;
    padding: var(--st-margin) 0;
}


/*BLOH ARTICLE*/

.article__heading {
	margin-top: var(--st-margin);
	}
.article__heading h3{
	font-size: 38px;
	text-align: center;
	margin-bottom: 10px;
}
.article__heading p{
	display: inline-block;
	margin-bottom: 33px;
}

.article__main-img {
	width: 100%;
	height: auto;
	margin-bottom: 24px;
}
.article__content {
	padding: 10px;
	font-size: 20px;

}
.article__info {
	font-size: 16px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 91px;
}
.article__info p {
	max-width: 254px;
	text-align: right;
	color: var(--info-text);
	transition: color .25s ease-in-out;
}
.article__info p:hover {
	color: var(--text);
}
.article__info p:first-child {
	text-align: left;
}


/*VSYAKA*/

.vsyaka__heading {
	text-align: center;
	margin-bottom: 12px;
}
.vsyaka__heading h3{
	font-size: 38px;
}
.vsyaka__heading p {
	font-size: 26px;
}

.vsyaka-item {
	padding: 36px 40px 78px;
	margin-bottom: var(--gutter);
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
}
.vsyaka-item::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 5px;
	left: 5px;
	background-image: url(../images/denim.png);
	background-repeat: repeat;
	background-size: 150%;
	border-radius: 0 0 45px 45px;
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
	z-index: -1;
}

.vsyaka-item__decoration {
	font-size: 100px;
	color: #fff;
	text-align: left;
	line-height: 20px;
	margin-top: 20px;
}
.vsyaka-item img {
	image-rendering: pixelated;
	image-rendering: crisp-edges;
	width: 100%;
	height: auto;
}
.vsyaka-item__title {
	font-size: 20px;
	padding: 10px;
	border-radius: 5px;
	margin-top: 20px;
	background-color: #fff;
	cursor: pointer;
	transition: transform .25s ease-in-out;
}
.vsyaka-item__title:hover {
	transform: translateY(10px);
}



.vsyaka__pagination{
	margin: 30px 0 61px;
}

/*ABOUT-US*/

.about-us {
	position: relative;
	background-size: 4%;
	margin: var(--st-margin) 0;
	padding: 38px 25px 65px 56px;
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
	z-index: 1;
}
.about-us h3 {
	font-size: 28px;
	text-decoration: underline;
	text-align: left;
	margin-bottom: 11px;
}
.about-us ol {
	font-size: 20px;
	border-bottom: 1px solid var(--text);
	display: inline-block;
	padding-bottom: 53px;
}
.about-us li {
	padding: 5px;
}
.about-us li a {
	border: none;
}
.about-us li span {
	text-decoration-line: line-through;
}
.about-us h4 {
	text-align: center;
	font-size: 28px;
	margin-bottom: var(--sm-margin);
}
.about-us article {
}

.about-us__pagination {
	margin-top: 45px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.about-us__pagination a{
	padding: 10px;
	background-color: #fff;
	border-radius: 5px;
	margin: var(--sm-margin);
	border: none;
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
}
.about-us__pagination a:first-child{
	justify-self: start;
}
.about-us__pagination a:nth-child(2)::before{
	content: '<< ';
}
.about-us__pagination a:nth-child(3)::after{
	content: ' >>';
}


/*HOROSCOPE*/

.horoscope {
	z-index: 100;
}
.horoscope__content {
	display: flex;
	position: relative;
}

.content__wheel {
	position: relative;
	margin: 0 auto;
	flex: 0 50%;
	text-align: center;
}
.wheel__options {
	margin: 0 auto;
	list-style-type: none;
	display: block;
	position: relative;
	width: 500px;
	height: 500px;
	border: 1px solid #000;
	border-radius: 100%;
}
.wheel__options li{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	text-align: center;
	padding: 15px;
}
.wheel__options li:nth-child(2){
	transform: rotate(60deg);
}
.wheel__options li:nth-child(3){
	transform: rotate(120deg);
}
.wheel__options li:nth-child(4){
	transform: rotate(180deg);
}
.wheel__options li:nth-child(5){
	transform: rotate(240deg);
}
.wheel__options li:nth-child(6){
	transform: rotate(300deg);
}
.content__wheel img {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	top: 0;
	margin: 0 auto;
    transition: transform 3s ease-in-out;
}

.content__wheel button {
	margin: var(--sm-margin) 0;
	font-size: 28px;
	padding: 2.5px 38px;
	border: 1px solid #000;
	cursor: pointer;
	background-color: #fff;
}
.content__wheel button:hover {
	background-color: cyan;
}

.content__desc {
	text-align: right;
	flex: 0 50%;
}
.content__desc p {	
	padding: 19px;
	text-align: right;
	border: 1px solid #000;
	border-radius: 19px 19px 0px 19px;
}

.horoscope-detail-wrapper {
	animation: k-horoscope-detail 3s ease-out;
}
.content__desc h4 {	
	font-size: 24px;
	text-align: left;
	margin-bottom: 10px;
}
.content__desc ul {	
	text-align: left;
	border: 1px solid var(--text);
}
.content__desc ul li{	
	padding: 0 10px 0 0;
}

/*HUMOR PUMP*/

.humor-pump {
	position: relative;
	cursor: pointer;
}
.humor-pump img {
	width: 100%;
	height: auto;
}
.humor-pump__worker{
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	transform-origin: 49% 46%;
	transition: transform .5s ease-in-out;
}
.humor-pump__worker:hover {
	animation: amimate-humor-pump 3s ease-in-out infinite alternate;
}

.modal-humor-pump {
	z-index: 100;
	padding: 19px;
}
.humor-pump__decoration {
	width: 100%;
	height: 160px;
	background-color: var(--pump-green);
	border-radius: 100%;
	position: relative;
}
.humor-pump__decoration::before {
	content: '';
	position: absolute;
	background-color: var(--pump-green);
	right: 40px;
	left: 40px;
	top: -260px;
	bottom: 40px;
	border-radius: 100% 100% 0 0 ;
	z-index: -1;
}
.humor-pump__decoration::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 20px;
	left: 20px;
	top: 20px;
	margin: 20px auto 0;
	background-color: #333;
	border-radius: 100%;
}

.humor-pump__content {
}
.humor-pump__content p{
	max-width: 600px;
	margin: var(--sm-margin) auto;
	text-align: center;
	animation: k-humor-pump-content 3s ease-out;
	z-index: 100;
	position: relative;
	margin-bottom: calc(var(--sm-margin)*2);
}


/*LOGBOOK*/

.logbook__content {
	display: flex;
	justify-content: center;
	align-items: start;
}
.logbook .modal__container {
	position: relative;
}
.logbook .modal__container::before {
	z-index: -1;
	background-size: 4%;
}
.logbook__content ul{
	flex: 0 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	list-style-position: inside;
}
.logbook__days-event {
	flex: 0 50%;
}
.logbook__content h3{
	text-align: center;
	font-size: 24px;
}


/*POEM*/

.poem__content {
	position: relative;
}

.poem__prev, .poem__next{
	position: absolute;
	width: 112px;
	height: 112px;
	background-color: transparent;
	z-index: -1;
	cursor: pointer;
	transition: border .25s ease-in-out;
}
.poem__prev {
	left: 0;
	top: 0;
	border-radius: 100% 0 0 0;
	border-top: 30px solid ;
}
.poem__prev:hover {
	border-top: 50px solid;
}
.poem__next	{
	bottom: -81px;
	right: -19px;
	border-bottom: 30px solid ;
	border-radius: 0 0 100% 0;
}
.poem__next:hover {
	border-bottom: 50px solid ;
}

.poem__heading {
    text-align: center;
}

.poem select, .poem select:active {
	border: none;
	background-color: transparent;
	outline: none;
	box-shadow: none;
    text-align: center;
    display: inline-block;
}

.poem__category {
    margin-left: -33px;
    font-size: 20px;
    color: var(--text);
}

/*T-SOCIETY*/


.t-society {
	position: relative;
	text-align: center;
}
.t-society img {
	width: 100%;
	height: auto;
}
.t-society img:first-child {
	transition: opacity 3s ease-in-out, transform 3s ease-in-out;
}
.t-society img:nth-child(2) {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	opacity: 0;
	transition: opacity 7s ease-in;
}
.t-society:hover img:nth-child(2){
	opacity: 1;
}
.t-society:hover img:nth-child(1){
	opacity: 0;
	transform: rotate(80deg);
}

.t-society__content{
	text-align: center;
}
.t-society__content img{
	width: 50%;
	height: auto;
}


/*MICROMAODAL*/

.modal__container {
	max-width: 1500px;
	font-family: underdog-ez;	
    z-index: 45000;
    position: relative;
}
.modal__title {
	font-size: 28px;
	text-align: center;
	color: var(--text);
	display: block;
}
.modal__close {
	float: right;
}
.modal__header .modal__close::before {
	content: 'X';
	font-family: underdog-ez;
	width: 23px;
	height: 23px;
	border: 1px solid var(--text);
	padding: 0 5px;
	transition: background-color .25s ease-in-out;
}
.modal__header .modal__close:hover::before {
	background-color: var(--bs-red);
}
.modal__header {
	display: flex;
	flex-direction: column;
	margin-bottom: 45px;
	display: block;
}


/*KEYFRAMES*/

@keyframes amimate-humor-pump {
	0%{
		transform: rotate(0deg);
	}
	50%{
		transform: rotate(80deg);
	}
	100% {
		transform: rotate(-30deg);
	}
	
}

@keyframes k-humor-pump-content {
	0%{
		opacity: 0;
		height: 0;
		transform: translateY(-200px);
		color: #fff;
		border-radius: 100%;
		padding: 49px 0;
	}
	80%{
		opacity: 1;
		color: var(--text);
		background-color: lightblue;
	}
	100%{
		height: 100%;
		transform: translateY(0);
		background-color: #fff;
	}
	
}

@keyframes k-horoscope-detail {
    0%{
        opacity:0;
        transform: matrix(1, 2, 3, 4, 5, 6);
    }
    100%{
        opacity:1;
    }
}
